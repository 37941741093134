<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps" :options="options">
    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
      <VOnboardingStep>
        <div class="bg-gradient-info shadow rounded-lg text-white m-2">
          <div class="px-4 py-5 sm:p-6">
            <div class="flex flex-col items-center justify-between gap-6">
              <div v-if="step.content" class="flex flex-col gap-4">
                <div class="flex items-center justify-between">
                  <div
                    v-if="step.content.title"
                    class="text-base font-semibold text-white"
                  >
                    {{ $t(step.content.title) }}
                  </div>
                  <BaseButton
                    small
                    :symbol="'close'"
                    :type="'icon-secondary'"
                    @click="endTour"
                  ></BaseButton>
                </div>
                <div v-if="step.content.description" class="max-w-xl text-sm">
                  <div>{{ $t(step.content.description) }}</div>
                </div>
              </div>
              <div class="w-full gap-4 flex items-end relative justify-end">
                <!-- <span
                  class="absolute right-0 bottom-full mb-2 mr-2 text-gray-600 font-medium text-xs"
                  >{{ `${index + 1}/${steps.length}` }}</span
                > -->
                <BaseButton
                  v-if="!isFirst"
                  :symbol="'chevron_left'"
                  :type="'flow-secondary'"
                  :label="'Previous'"
                  @click="previous"
                ></BaseButton>
                <BaseButton
                  v-if="!isLast"
                  iconRight
                  :symbol="'chevron_right'"
                  :type="'flow-primary'"
                  :label="'Next'"
                  @click="next"
                ></BaseButton>
                <BaseButton
                  v-else
                  iconRight
                  :symbol="'check'"
                  :type="'flow-primary'"
                  :label="'Finish'"
                  @click="endTour"
                ></BaseButton>
              </div>
            </div>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script lang="ts" setup>
import {
  VOnboardingWrapper,
  VOnboardingStep,
  useVOnboarding,
} from 'v-onboarding'

import onboardingTour from '~/assets/ctas/onboarding-tour.json'
const { $bus, $features } = useNuxtApp()
const configStore = useConfig()
const settings = ref(configStore.config || {})
const tourLevel = ref(null)
const windowWidth = ref(window.innerWidth) // Necessary to handle isMobileCheck

const wrapper = ref(null)
const { start, goToStep, finish } = useVOnboarding(wrapper)
const options = {
  overlay: {
    enabled: true,
    padding: 0,
    borderRadius: 0,
    preventOverlayInteraction: false,
  },
  scrollToStep: {
    enabled: true,
    options: {
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    },
  },
  autoFinishByExit: true,
}
const steps = ref([])

// Watch for config changes
watch(
  () => configStore.config,
  newConfig => {
    if (newConfig) {
      settings.value = { ...newConfig }
      // if one property of the COMPLETED TOURS is not in the settings.onBoardingTours, then add it
      if (
        !settings.value.onboardingTours ||
        Object.keys(settings.value.onboardingTours).length === 0
      ) {
        settings.value.onboardingTours = { ...COMPLETED_TOURS }
      }
      Object.keys(COMPLETED_TOURS).forEach(key => {
        if (!settings.value.onboardingTours[key]) {
          settings.value.onboardingTours[key] = COMPLETED_TOURS[key]
        }
      })
    }
  },
  { immediate: true }
)

onMounted(async () => {
  window.addEventListener('resize', handleResize)
  $bus.on('startOnboarding', startDeskOnboarding)
  if (!configStore.config) {
    await configStore.fetchConfig()
  }
  settings.value = { ...configStore.config }

  if (
    !settings.value.onboardingTours ||
    Object.keys(settings.value.onboardingTours).length === 0
  ) {
    settings.value.onboardingTours = { ...COMPLETED_TOURS }
  }

  Object.keys(COMPLETED_TOURS).forEach(key => {
    if (!settings.value.onboardingTours[key]) {
      settings.value.onboardingTours[key] = COMPLETED_TOURS[key]
    }
  })
  // show onboarding if the user has never seen the onboarding before
  if (
    $features.onboardingTour &&
    !settings.value.onboardingTours.deskCompleted
  ) {
    startDeskOnboarding()
  }
})

onUnmounted(() => {
  $bus.off('startOnboarding', startDeskOnboarding)
  window.removeEventListener('resize', handleResize)
})

function startDeskOnboarding() {
  tourLevel.value = 'desk'
  steps.value = onboardingTour.find(step => step.for === tourLevel.value).steps

  // integrate for mobile
  if (isMobile.value) {
    steps.value.forEach((step, index) => {
      if (index === 0 || index === 2) {
        steps.value[index] = {
          ...step,
          on: {
            beforeStep: () => $bus.emit('openDrawer'),
            afterStep: () => $bus.emit('closeDrawer'),
          },
        }
      }
      if (index === 4) {
        steps.value[index] = {
          ...step,
          on: {
            beforeStep: () => $bus.emit('openMenu'),
            afterStep: () => $bus.emit('closeMenu'),
          },
        }
      }
    })
  }

  start()
}

function endTour() {
  finish()
  if (
    tourLevel.value === 'desk' &&
    !settings.value.onboardingTours.deskCompleted
  ) {
    settings.value.onboardingTours = {
      ...settings.value.onboardingTours,
      deskCompleted: true,
    }
    configStore.updateConfig(settings.value)
  }
}

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

const isMobile = computed(() => {
  return windowWidth.value < 1024
})
</script>

<style>
:root {
  --v-onboarding-overlay-z: 100000;
  --v-onboarding-step-z: 100000;
  --v-onboarding-step-arrow-background: linear-gradient(
    90deg,
    #193060 0.01%,
    #2a59bb 99.99%
  );
  --v-onboarding-step-arrow-size: 14px;
}

[data-v-onboarding-wrapper] [data-popper-arrow]::before {
  content: '';
  background: var(--v-onboarding-step-arrow-background, white);
  top: 0;
  left: 0;
  transition: transform 0.2s ease-out, visibility 0.2s ease-out;
  visibility: visible;
  transform: translateX(0px) rotate(45deg);
  transform-origin: center;
  width: var(--v-onboarding-step-arrow-size, 10px);
  height: var(--v-onboarding-step-arrow-size, 10px);
  position: absolute;
  z-index: -1;
}

[data-v-onboarding-wrapper]
  [data-popper-placement='bottom']
  > [data-popper-arrow] {
  top: 1px;
  z-index: -1;
}

[data-v-onboarding-wrapper]
  [data-popper-placement='top']
  > [data-popper-arrow] {
  bottom: 15px;
  z-index: -1;
}
</style>
