<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog :open="open" @close="closeDialog" class="relative z-50">
      <!-- Gray Background Not sure if we want that-->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/25 transition-opacity" />
      </TransitionChild>

      <!-- Main visible Dialog -->
      <div
        role="dialog"
        class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-40"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            role="search"
            class="mx-auto max-w-[640px] transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all"
          >
            <Combobox v-model="selected" nullable>
              <div class="grid grid-cols-1 border-b border-gray-50">
                <ComboboxInput
                  :displayValue="_ => query"
                  class="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 md:text-base sm:text-sm text-primary outline-none placeholder:text-gray-300"
                  placeholder="Search..."
                  @change="updateQuery"
                />

                <i
                  class="mdi mdi-magnify col-start-1 row-start-1 ml-4 text-xl self-center text-primary pointer-events-none"
                ></i>
              </div>

              <div>
                <div class="flex flex-row" v-if="query !== ''">
                  <nav
                    class="flex px-4 py-2 space-x-1 overflow-x-auto"
                    aria-label="Tabs"
                  >
                    <button
                      v-for="tab in tabs"
                      :key="tab"
                      :class="[
                        tab === currentTab
                          ? 'bg-primary text-white'
                          : 'bg-ui-lightest text-primary hover:text-white hover:bg-primaryG-500',
                        'rounded-[4px] px-1.5 py-0.5 text-xs font-semibold cursor-pointer whitespace-nowrap',
                      ]"
                      :aria-current="tab ? 'tab' : undefined"
                      @click="selectTab(tab)"
                    >
                      {{ tab }} ({{ getResultNum(tab) }})
                    </button>
                  </nav>

                  <div
                    v-if="showCheckbox"
                    class="flex items-center ml-auto mr-4 px-1.5 py-0.5"
                  >
                    <div class="">
                      <input
                        type="checkbox"
                        id="limitToCurrent"
                        v-model="limitToCurrent"
                        class="mr-2 custom-input"
                        @click="setLimitToCurrent()"
                      />
                      <label
                        for="limitToCurrent"
                        class="custom-text"
                      >
                        {{ $t(checkboxLabel) }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="isLoading"
                class="border-t border-gray-100 align-self-stretch w-full text-primary font-semibold py-8 px-9"
              >
                <div class="app-loading__item m-auto" role="status">
                  <span class="app-loading__item__inner"
                    >Loading invitation</span
                  >
                </div>
              </div>
              <div
                v-else-if="
                  query !== '' &&
                  !isLoading &&
                  (collections.length > 0 ||
                    documents.length > 0 ||
                    fulltextRes.length > 0 ||
                    files.length > 0)
                "
              >
                <ComboboxOptions static class="-space-y-[1em]">
                  <li
                    v-if="currentTab === 'All' || currentTab === 'Collections'"
                  >
                    <QuickSearchResults
                      :elements="filteredCollections"
                      name="Collections"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>

                  <li v-if="currentTab === 'All' || currentTab === 'Documents'">
                    <QuickSearchResults
                      :elements="filteredDocuments"
                      name="Documents"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>

                  <li v-if="currentTab === 'All' || currentTab === 'Full text'">
                    <QuickSearchResults
                      :elements="filteredFulltextRes"
                      name="Full text"
                      @selectTab="openFullTextSearch"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>

                  <li
                    v-if="
                      (currentTab === 'All' || currentTab === 'Files') &&
                      isInDocument
                    "
                  >
                    <QuickSearchResults
                      :elements="filteredFiles"
                      name="Files"
                      @selectTab="selectTab"
                      @onSelect="onSelect"
                      :currentTab="currentTab"
                    />
                  </li>
                </ComboboxOptions>
              </div>

              <!-- No results-->
              <div
                v-else-if="
                  query !== '' &&
                  !isLoading &&
                  collections.length === 0 &&
                  documents.length === 0 &&
                  fulltextRes.length === 0 &&
                  files.length === 0
                "
                class="border-t border-gray-100 px-6 py-8 text-center text-sm sm:px-14"
              >
                <img
                  class="place-self-center w-30 h-30 mx-auto mb-6"
                  loading="lazy"
                  src="~/assets/images/search-icon-big.svg"
                />

                <p class="text-sm text-gray-300 text-balance">
                  {{ $t("We didn't find any results for your search.") }}
                  <br />
                  {{ 'How about trying a different keyword?' }}
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineEmits, defineProps, watch } from 'vue'
import { routerKey, useRoute } from 'vue-router'

import {
  Dialog,
  DialogPanel,
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import { debounce } from '~/utils/misc.js'
import QuickSearchResults from './QuickSearchResults.vue'

let tabs = ref([
  'All',
  'Collections',
  'Documents',
  // 'Files'
  //  'Models',
  //  'Sites',
  'Full text',
])
const currentTab = ref('All')

const { $bus, $sentry, $router } = useNuxtApp()
const { t } = useI18n()

const transformComp = useTransform()
const searchApi = useSearch()
const collectionsApi = useCollections()
const route = useRoute()

const query = ref('')
const isLoadingColls = ref(false)
const isLoadingDocuments = ref(false)
const isLoadingFulltextRes = ref(false)
const isLoadingFiles = ref(false)
const isLoadingDocMeta = ref(false)
const queryUpdate = ref(false)

const collections = ref([])
const documents = ref([])
const fulltextRes = ref([])
const files = ref([])
const selected = ref(null)
const limitToCurrent = ref(false)
let docMeta = null

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
})

watch(
  () => props.open,
  newVal => {
    if (newVal) {
      let collectionid = route.params.collectionid
      let docId = route.params.docid
      if (collectionid && docId) {
        tabs.value = ['All', 'Collections', 'Documents', 'Files', 'Full text']
      } else {
        tabs.value = ['All', 'Collections', 'Documents', 'Full text']
      }
    }
  }
)

const emit = defineEmits(['close'])
const closeDialog = () => {
  collections.value = []
  documents.value = []
  fulltextRes.value = []
  docMeta = null
  files.value = []
  emit('close', false)
}

const selectTab = tab => {
  currentTab.value = tab
}

const getResultNum = tab => {
  switch (tab) {
    case 'Collections':
      return collections ? collections.value.length : 0
    case 'Documents':
      return documents ? documents.value.length : 0
    case 'Full text':
      return fulltextRes ? fulltextRes.value.length : 0
    case 'Files':
      return files ? files.value.length : 0
    case 'All':
      if (
        !collections.value &&
        !documents.value &&
        !fulltextRes.value &&
        !files.value
      )
        return 0

      return (
        collections.value.length +
        documents.value.length +
        fulltextRes.value.length +
        files.value.length
      )
    default:
      return 0
  }
}

const openFullTextSearch = () => {
  $router.push({
    name: 'search',
    query: {
      term: query.value,
    },
  })
  closeDialog()
}

const onSelect = (value, resultType) => {
  selected.value = value

  let url = ''
  switch (resultType) {
    case 'Files':
      const collId = route.params.collectionid
      const docId = route.params.docid
      const pageId = value.pageNr
      url = `/collection/${collId}/doc/${docId}/edit?pageid=${pageId}`
      break
    case 'Documents':
      let colId = value.mainColId
      if (!colId) {
        colId = value.collectionList?.colList[0]?.colId
      }
      url = `/collection/${colId}/doc/${value.docId}`
      break
    case 'Collections':
      url = `/collection/${value.colId}`
      break
    case 'Full text':
      const fullTextCollId = value.meta.collections[0]
      const fullTextDocId = value.id.document
      const fullTextPageId = value.id.page

      const rectCoords = value.results.map(r => r.rect)
      const rectCoordsJson = JSON.stringify(rectCoords)
      const rectCoordsBase64 = btoa(rectCoordsJson) // Convert JSON to base64

      url = `/collection/${fullTextCollId}/doc/${fullTextDocId}/edit?pageid=${fullTextPageId}&term=${query.value}&rectCoords=${rectCoordsBase64}`
      break
    default:
      $bus.emit('notification', {
        message: t('Could not forward to search result.'),
        type: 'error',
      })
      $sentry.captureMessage(
        `Could not open search result for value: ${JSON.stringify(
          value
        )}, search term: ${query.value}`
      )
      return
  }

  if (url.startsWith('/')) {
    $router.push(url)
  } else {
    window.location.href = url
  }
  closeDialog()
}

const canSubmit = value => {
  return typeof value === 'string' && value !== '' && value.length > 2
}

const isLoading = computed(() => {
  return (
    isLoadingColls.value ||
    isLoadingDocuments.value ||
    isLoadingFulltextRes.value ||
    isLoadingFiles.value ||
    isLoadingDocMeta.value ||
    queryUpdate.value
  )
})

const setLimitToCurrent = async () => {
  queryUpdate.value = true
  limitToCurrent.value = !limitToCurrent.value
  console.log(
    'Limit to current',
    limitToCurrent.value,
    docMeta,
    isInDocument.value
  )
  if (limitToCurrent.value && docMeta === null && isInDocument.value) {
    await fetchDocMeta()
  }
  debouncedUpdateQuery()
}

const filteredCollections = computed(() => {
  if (currentTab.value === 'All') {
    return collections.value.slice(0, 3)
  }
  return collections.value
})

const filteredDocuments = computed(() => {
  if (currentTab.value === 'All') {
    return documents.value.slice(0, 3)
  }
  return documents.value
})

const filteredFulltextRes = computed(() => {
  if (currentTab.value === 'All') {
    return fulltextRes.value.slice(0, 3)
  }
  return fulltextRes.value
})

const filteredFiles = computed(() => {
  if (currentTab.value === 'All') {
    return files.value.slice(0, 3)
  }
  return files.value
})

const updateQuery = e => {
  selected.value = null
  queryUpdate.value = true
  query.value = e.target.value
  debouncedUpdateQuery(1000)

  if (query.value === '') {
    collections.value = []
    documents.value = []
    fulltextRes.value = []
    files.value = []
  }
}

const debouncedUpdateQuery = debounce(() => {
  if (canSubmit(query.value)) {
    getFilteredCollection(query.value)
    getFilteredDocuments(query.value)
    getFullTextResults(query.value)
    getFiles(query.value)
  }
  queryUpdate.value = false
  
}, 1000)

const getFilteredCollection = async query => {
  isLoadingColls.value = true
  let data
  try {
    data = await collectionsApi.filterCollections({
      offset: 0,
      filter: query,
    })

    collections.value = data.trpCollection.reverse()
    isLoadingColls.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingColls.value = false
    return
  }
}

const getFilteredDocuments = async query => {
  isLoadingDocuments.value = true
  let dataByTitle = []
  let dataById = []
  try {
    dataByTitle = await collectionsApi.findDocuments({
      title: query,
      collId: limitToCurrent.value ? route.params.collectionid : null,
    })

    const isQueryNumber = !isNaN(Number(query))
    if (isQueryNumber) {
      dataById = await collectionsApi.findDocuments({
        id: query,
        collId: limitToCurrent.value ? route.params.collectionid : null,
      })
    }

    // remove duplicates
    let filtered = dataById.filter(
      d => !dataByTitle.some(d2 => d2.docId === d.docId)
    )

    let data = filtered.concat(dataByTitle)

    documents.value = data
    isLoadingDocuments.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingDocuments.value = false
    return
  }
}

const getFullTextResults = async query => {
  isLoadingFulltextRes.value = true
  let data
  try {
    let filter = []
    if (limitToCurrent.value && isInDocument.value) {
      filter.push(`colId:"${route.params.collectionid}"`)
      filter.push(`f_title:"${docMeta?.title}"`)
    } else if (limitToCurrent.value && isInCollection.value) {
      filter.push(`colId:"${route.params.collectionid}"`)
    }

    data = await searchApi.fetchSearchFulltext({
      term: query + '~0', // ~0 to search for exact match; fuzziness=0
      start: 0,
      rows: 100,
      filter: filter,
    })
    let transformData = transformComp.transform(data)
    fulltextRes.value = transformData.items
    isLoadingFulltextRes.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingFulltextRes.value = false
    return
  }
}

const isInDocument = computed(() => {
  let collectionid = route.params.collectionid
  let docId = route.params.docid
  return collectionid && docId
})

const isInCollection = computed(() => {
  let collectionid = route.params.collectionid
  let docId = route.params.docid
  return collectionid && (!docId || docId === '')
})

const showCheckbox = computed(() => {
  return isInCollection.value || isInDocument.value
})

const checkboxLabel = computed(() => {
  return isInDocument.value ? 'Current document' : 'Current collection'
})

const getFiles = async query => {
  let collectionid = route.params.collectionid
  let docId = route.params.docid
  if (!collectionid || !docId) {
    return
  }

  isLoadingFiles.value = true
  let data
  try {
    data = await collectionsApi.fetchPagesString({
      docId: docId,
      collId: collectionid,
      filename: query,
    })
    files.value = data
    isLoadingFiles.value = false
  } catch (err) {
    $sentry.captureException(err)
    isLoadingFiles.value = false
    return
  }
}
const fetchDocMeta = async () => {
  isLoadingDocMeta.value = true
  docMeta = await collectionsApi.fetchDocumentsMeta({
    collId: route.params.collectionid,
    docId: route.params.docid,
  })
  isLoadingDocMeta.value = false
}
</script>
<style>
.custom-text {
  @apply text-xs text-primary font-semibold;
}
.custom-input {
  @apply checkbox checkbox-primary align-middle rounded h-3.5 w-3.5;
}

</style>
