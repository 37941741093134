<template>
  <nav class="app-navbar__tabs" ref="tabs">
    <ul class="app-navbar__tabs__list">
      <li
        v-for="(item, index) in navbarItems"
        :key="index"
        class="app-navbar__tabs__item"
        :class="{
          active: isActive(item),
          'app-animation--loading': loadingIndex === index,
        }"
        :id="item.name === 'Home' ? 'onboarding-desk4' : ''"
      >
        <NuxtLink
          @click="handleClick(index, item, $event)"
          :to="{ path: localePath(item.path), query: item.query }"
          class="app-navbar__tabs__link"
        >
          {{ $t(item.name) }}
        </NuxtLink>
      </li>
    </ul>

    <!-- Render a NavbarSearch unless we're on restricted pages -->
    <NavbarSearchNew
      v-if="!pagesWithoutSearch.includes(currentPage)"
      name="term"
    />
  </nav>
</template>

<script lang="ts" setup>
const route = useRoute()
const localePath = useLocalePath()
const { userProfile } = useKeycloak()
const { $features } = useNuxtApp()

const loadingIndex = ref<number | null>(null)

/**
 * Reactive "currentPage" identifies which main route we’re on.
 * This clarifies the active tab logic and whether to show the search bar.
 */
const currentPage = computed(() => {
  const { path } = route

  // if (path.includes('tags')) return '/tags'
  if (path.includes('collection')) return '/collection'
  if (path.includes('training/gallery')) return '/training/gallery'
  if (path.includes('training')) return '/training'
  if (path.includes('sites')) return '/sites'
  if (path.includes('search')) return '/search'
  if (path.includes('models')) return '/models'
  if (path.includes('data-lab')) return '/data-lab'

  return '/'
})

/**
 * PAGES that should NOT show the NavbarSearch.
 */
const pagesWithoutSearch = [
  '/sites',
  '/models',
  '/data-lab',
  '/training',
  '/training/gallery',
]

/**
 * Build the navigation items based on current route and user profile.
 */
const navbarItems = computed(() => {
  const { path, query } = route

  // 1) SITES
  if (path.includes('sites')) {
    if (userProfile.IsAdmin) {
      return [
        { name: 'My sites', path: '/sites', query: { view: 'private' } },
        { name: 'Public sites', path: '/sites', query: { view: 'public' } },
        { name: 'Admin', path: '/sites', query: { view: 'admin' } },
      ]
    } else {
      return [
        { name: 'My sites', path: '/sites', query: { view: 'private' } },
        { name: 'Public sites', path: '/sites', query: { view: 'public' } },
      ]
    }
  }

  // 2) TRAINING
  if (path.includes('training')) {
    return [
      { name: 'Dashboard', path: '/training' },
      { name: 'Gallery', path: '/training/gallery' },
    ]
  }

  // 3) MODELS / DATA-LAB
  if (path.includes('models') || path.includes('data-lab')) {
    return [
      { name: 'Models', path: '/models' },
      { name: 'Training lab', path: '/data-lab' },
    ]
  }

  // 4) DEFAULT (HOME, COLLECTION, TAGS, ... + optional Invitations)
  const baseRoutes = [
    { name: 'Home', path: '/' },
    { name: 'Collections', path: '/collection' },
    // { name: 'Tags', path: '/tags' },
  ]
  baseRoutes.push({ name: 'Search', path: '/search' })

  return baseRoutes
})

/**
 * WATCHERS
 * Reset the loading indicator whenever the route changes.
 */
watch(
  () => route.fullPath,
  () => {
    setTimeout(() => {
      loadingIndex.value = null
    }, 100)
  }
)

/**
 * METHODS
 * isActive checks if a given item is active based on currentPage and route query.
 */
function isActive(item: any): boolean {
  if (currentPage.value === '/sites') {
    // For /sites, we rely on query.view to differentiate sub-routes
    if (route.query.view === undefined) {
      // default to 'private'
      return item.query?.view === 'private'
    } else {
      return item.query?.view === route.query.view
    }
  }
  // For everything else, just check path
  return item.path === currentPage.value
}

/**
 * handleClick sets the loadingIndex if we're navigating to a new page (and not using ctrl/cmd).
 */
function handleClick(index: number, item: any, event: MouseEvent): void {
  // 1) If the item is already active, or user is opening in new tab (ctrl/cmd), do NOT set loading index.
  const isAlreadyActive = isActive(item)
  const isNewTab = event.ctrlKey || event.metaKey

  if (!isAlreadyActive && !isNewTab) {
    loadingIndex.value = index
  }
}
</script>

<style scoped>
/* CB: Commenting this out bc it's messing with the main navbar styling... */

/* Example styling; adapt as needed */
/* .app-navbar__tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-navbar__tabs__list {
  display: flex;
  list-style: none;
}
.app-navbar__tabs__item {
  margin-right: 1rem;
}
.app-navbar__tabs__item.active .app-navbar__tabs__link {
  font-weight: bold;
  color: var(--color-primary);
}
.app-animation--loading {
  Example loading animation style
  opacity: 0.6;
} */
</style>
