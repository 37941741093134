<template>
  <div
    class="flex items-center w-full relative h-full"
  >
    <!-- search icon -->
    <i
      class="mdi mdi-magnify inline-flex lg:hidden text-2xl cursor-pointer app-animation absolute right-6"
      @click="openSearchDialog"
    ></i>
    <!-- search wrapper -->
    <div
      class="app-navbar__search__wrapper dynamic-right-margin max-w-[296px] "
    >
      <!-- Full text -->
      <BaseInput
        :placeholder="$t('Search ...')"
        :leading="'magnify'"
        :name="'search'"
        :type="'search'"
        :id="'navbar-search-input'"
        class="app-navbar__search w-full flex flex-1"
        @input="openSearchDialog" 
        @click="openSearchDialog"
      />
      <!-- if there is already input use it in the dialog-->
    </div>
    <SearchDialog :open="isSearchDialogOpen" @close="closeDialog" />
  </div>
</template>

<script setup>
const isSearchDialogOpen = ref(false)

const openSearchDialog = () => {
  if (!isSearchDialogOpen.value) {
    isSearchDialogOpen.value = true
  }
}
const closeDialog = () => {
  isSearchDialogOpen.value = false
}


const focusSearchBar = () => {
  openSearchDialog()
}

const handleKeydown = (event) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
    event.preventDefault()
    focusSearchBar()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})

</script>

<style scoped>
.dynamic-right-margin {
  margin-right: 50%;
  margin-left: auto;
  transform: translateX(-25%);
}
</style>