<template>
  <div>
    <h2 class="quicksearch-results__header">
      <span>{{ $t(props.name) }}</span>
      <span
        v-show="currentTab === 'All' || props.name === 'Full text'"
        @click="emit('selectTab', props.name)"
        class="cursor-pointer"
        tabindex="0"
      >
        {{ $t('See all') }}
        <i class="mdi mdi-arrow-right"></i>
      </span>
    </h2>
    <ul class="quicksearch-results__list">
      <ComboboxOption
        v-for="elem in elements"
        :key="getElemKey(elem)"
        :value="elem"
        as="template"
        v-slot="{ active }"
      >
        <li
          :class="[
            'cursor-default select-none px-4 py-2',
            active && 'bg-gray-50 text-primary outline-none',
          ]"
          @click="onSelect(elem, props.name)"
          tabindex="0"
        >
          <div class="flex flex-row">
            <i
              v-if="props.name === 'Collections'"
              class="mdi mdi-folder place-self-center text-gray-300 cursor-pointer"
              @click="selectTab('Documents')"
            />

            <i
              v-if="props.name === 'Documents'"
              class="mdi mdi-file place-self-center text-gray-300 cursor-pointer"
              @click="selectTab('Collections')"
            />

            <i
              v-if="props.name === 'Full text'"
              class="mdi mdi-text place-self-center text-gray-300"
              @click="selectTab('Full text')"
            />

            <i
              v-if="props.name === 'Files'"
              class="mdi mdi-file-document-outline place-self-center text-gray-300"
              @click="selectTab('Files')"
            />

            <div class="flex flex-col">
              <span class="quicksearch-results__text">
                <span class="quicksearch-results__title">
                  {{ getElemName(elem) }}
                </span>
                <span class="text-primary" v-if="props.name !== 'Full text' && props.name !== 'Files'"
                  >({{ getElemKey(elem) }})</span
                >
              </span>
              <span
                class="quicksearch-results__details"
                v-if="props.name === 'Documents'"
              >
                {{ elem.nrOfPages }} {{ $t('Pages') }}
              </span>
              <span
                class="quicksearch-results__details"
                v-if="props.name === 'Collections'"
              >
                {{ elem.nrOfDocuments }} {{ $t('Documents') }}
              </span>

              <span
                class="quicksearch-results__details"
                v-if="props.name === 'Full text'"
              >
                {{ elem.meta.title }} &#8226;
                {{ $t('Page') }} {{ elem.meta.page }}
              </span>

              <span
                class="quicksearch-results__details"
                v-if="props.name === 'Files'"
              >
                {{ parseInt(elem?.fileSize / 1000)}} KB &#8226; 
                <BaseDate :value="elem?.created" />
              </span>
            </div>
          </div>
        </li>
      </ComboboxOption>
    </ul>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import { ComboboxOption } from '@headlessui/vue'

const props = defineProps({
  elements: Array,
  name: String,
  currentTab: String,
})

const emit = defineEmits(['selectTab', 'onSelect'])

function getElemKey(elem) {
  if (props.name === 'Documents') {
    return elem.docId
  } else if (props.name === 'Collections') {
    return elem.colId
  }
}

function getElemName(elem) {
  if (props.name === 'Documents') {
    return elem.title
  } else if (props.name === 'Collections') {
    return elem.colName
  } else if (props.name === 'Files') {
    return elem.imgFileName
  } else if (props.name === 'Full text') {
    const fullText = elem.items[0].map(item => item.string).join('')
    if (fullText.length > 30) {
      const start = Math.floor((fullText.length - 30) / 2)
      return fullText.slice(start, start + 30)
    }
    return fullText
  }
}

const onSelect = (elem, type) => {
  emit('onSelect', elem, type)
}
</script>
